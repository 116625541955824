import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { AllHouseFilter } from "../../atom";
import DropDown from "../../DropDown/DropDown";
import { useBlockStatistic, useBlockStatistics } from "../../queries/queries";
import { BASE_URL_VISUAL } from "../../services/RequestService";
import styles from "./visual.module.scss";
import queryString from "query-string";

const Visual = () => {
  const filter = useRecoilValue<any>(AllHouseFilter);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imageSize, setImageSize] = useState({
    width: 1920,
    height: 1080,
    clientWidth: 0,
  });
  const [dropChildren, setDropChildren] = useState<JSX.Element>(<></>);
  const [positionDown, setPositionDown] = useState({
    top: 0,
    left: 0,
    visible: false,
  });
  const simple = queryString.parse(window.location.search)?.simple;
  const blockId = Number(queryString.parse(window.location.search)?.blockId);

  const { data: dataAllHouse } = useBlockStatistics();
  const { data } = useBlockStatistic(
    simple
      ? dataAllHouse
        ? filter?.houseId || blockId || dataAllHouse[0]?.id
        : 0
      : filter?.houseId
  );
  const blockMouseMove = async (e: any) => {
    if (window.innerHeight <= e?.clientY + 350) {
      setPositionDown({
        left: e?.clientX + 20,
        top: window.innerHeight - 350,
        visible: true,
      });
    } else {
      setPositionDown({
        left: e?.clientX + 20,
        top: e?.clientY + 20,
        visible: true,
      });
    }
  };

  const blockMouseOut = (e: any) => {
    setDropChildren(<></>);
    if (window.innerHeight <= e?.clientY + 350) {
      setPositionDown({
        left: e?.clientX + 20,
        top: window.innerHeight - 350,
        visible: false,
      });
    } else {
      setPositionDown({
        left: e?.clientX + 20,
        top: e?.clientY + 20,
        visible: false,
      });
    }
  };

  const loadImage = (e: any) => {
    setImageSize({
      width: e.naturalWidth,
      height: e.naturalHeight,
      clientWidth: e.clientWidth,
    });
  };

  useEffect(() => {
    const resize = new ResizeObserver((entries) => {
      const [entry] = entries;
      setImageSize((prev) => ({
        ...prev,
        clientWidth: entry?.target.clientWidth,
      }));
    });

    if (imageRef.current) {
      resize.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resize.unobserve(imageRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageRef.current]);

  return (
    <div className={styles.visual}>
      <div className={styles.visual_center}>
        <div
          className={styles.visual_center_child}
          style={{ minWidth: imageSize?.clientWidth }}
        >
          <img
            src={BASE_URL_VISUAL + data?.houseImagePath}
            alt=""
            className={styles.blurImage}
          />
          <img
            ref={imageRef}
            id="visualHouseImage"
            src={BASE_URL_VISUAL + data?.houseImagePath}
            alt=""
            onLoad={(e) => {
              loadImage(e.target);
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid slice"
            viewBox={`0 0 ${imageSize.width} ${imageSize.height}`}
          >
            {data?.visualViewBlockStatisticWithImages.map((item) => (
              <>
                <Link
                  key={item.blockId}
                  to={`/visual/${item.blockId + window.location.search}`}
                  onMouseEnter={() => {
                    setDropChildren(<></>);
                    let children = (
                      <div>
                        <h1>{item.blockName}-blok</h1>
                        <div>
                          <h1>{item.totalFlatCount} xonadon</h1>
                          <div>
                            {item.roomsCount.map((room) => (
                              <p key={room.roomsCount}>
                                {room.rooms} xonali{" "}
                                <b>{room.roomsCount} dona</b>
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    );
                    setTimeout(() => {
                      setDropChildren(children);
                    }, 0);
                  }}
                  onMouseMove={(e) => {
                    blockMouseMove(e);
                  }}
                  onMouseOut={blockMouseOut}
                >
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d={item.buildingSVGImage[0]?.backgroundImage}
                  />
                  <polygon
                    xmlns="http://www.w3.org/2000/svg"
                    points={item.buildingSVGImage[0]?.frontImage}
                  />
                </Link>
              </>
            ))}
          </svg>
        </div>
      </div>
      <DropDown
        left={positionDown.left}
        top={positionDown.top}
        visible={positionDown.visible}
      >
        {dropChildren}
      </DropDown>
    </div>
  );
};

export default Visual;
