import React from "react";

const Element3Icon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7273 5.42183V2.53274C12.7273 1.63546 12.3201 1.27274 11.3082 1.27274H8.73733C7.72551 1.27274 7.31824 1.63546 7.31824 2.53274V5.41546C7.31824 6.3191 7.72551 6.67546 8.73733 6.67546H11.3082C12.3201 6.68183 12.7273 6.3191 12.7273 5.42183Z"
        fill="#757D8A"
      />
      <path
        d="M12.7273 11.3082V8.73727C12.7273 7.72545 12.3201 7.31818 11.3082 7.31818H8.73733C7.72551 7.31818 7.31824 7.72545 7.31824 8.73727V11.3082C7.31824 12.32 7.72551 12.7273 8.73733 12.7273H11.3082C12.3201 12.7273 12.7273 12.32 12.7273 11.3082Z"
        fill="#757D8A"
      />
      <path
        d="M6.6818 5.42183V2.53274C6.6818 1.63546 6.27452 1.27274 5.26271 1.27274H2.6918C1.67998 1.27274 1.27271 1.63546 1.27271 2.53274V5.41546C1.27271 6.3191 1.67998 6.67546 2.6918 6.67546H5.26271C6.27452 6.68183 6.6818 6.3191 6.6818 5.42183Z"
        fill="#757D8A"
      />
      <path
        d="M6.6818 11.3082V8.73727C6.6818 7.72545 6.27452 7.31818 5.26271 7.31818H2.6918C1.67998 7.31818 1.27271 7.72545 1.27271 8.73727V11.3082C1.27271 12.32 1.67998 12.7273 2.6918 12.7273H5.26271C6.27452 12.7273 6.6818 12.32 6.6818 11.3082Z"
        fill="#757D8A"
      />
    </svg>
  );
};

export default Element3Icon;
