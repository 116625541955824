import React, { useEffect } from "react";
import styles from "./header.module.scss";
import Logo from "../../assets/images/Logo.png";
import { Select } from "antd";
import { useAllBuildingBy, useAllHouseBy } from "../../queries/queries";
import { useRecoilState, useSetRecoilState } from "recoil";
import { AllHouseFilter, HouseReload } from "../../atom";
import queryString from "query-string";

const Header = () => {
  const [filter, setFilter] = useRecoilState<any>(AllHouseFilter);
  const setReload = useSetRecoilState(HouseReload);
  const { data: allHouseByData } = useAllHouseBy();
  const { data: allBuildingByData } = useAllBuildingBy(filter?.houseId);

  const simple = queryString.parse(window.location.search)?.simple;

  useEffect(() => {
    if (allHouseByData) {
      setFilter({
        ...filter,
        houseId: allHouseByData[allHouseByData.length - 1]?.id,
      });
    }
    // eslint-disable-next-line
  }, [allHouseByData]);

  return (
    <div className={styles.header}>
      <div className={styles.header_left}>
        <span className={styles.header_left_logo}>
          <img src={Logo} alt="" />
        </span>
        {!simple && (
          <>
            <Select
              className="customSelect"
              style={{ width: 386 }}
              placeholder="Turar-joy majmuasi"
              value={filter?.houseId}
              onChange={(value) => {
                setFilter({
                  ...filter,
                  houseId: value,
                  buildingId: null,
                  rooms: [1, 2, 3, 4, 5],
                  maxArea: 160,
                  minArea: 0,
                  maxPrice: 2000000000,
                  minPrice: 1000000,
                  maxPerPrice: 15000000,
                  minPerPrice: 1000000,
                  repaired: "null",
                  empty: false,
                  attached: false,
                });
                setReload(true);
              }}
            >
              {allHouseByData?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              className="customSelect"
              style={{ width: 240 }}
              placeholder="Bino "
              value={filter?.buildingId}
              onChange={(value) => {
                setFilter({
                  ...filter,
                  buildingId: value,
                  rooms: [1, 2, 3, 4, 5],
                  maxArea: 160,
                  minArea: 0,
                  maxPrice: 2000000000,
                  minPrice: 1000000,
                  maxPerPrice: 15000000,
                  minPerPrice: 1000000,
                  repaired: "null",
                  empty: false,
                  attached: false,
                });
                setReload(true);
              }}
            >
              {allBuildingByData?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
        {/* <Select
          className="customSelect"
          style={{ width: 240 }}
          placeholder="Bino bo’limi"
        >
          {" "}
        </Select> */}
      </div>
      {/* <div className={styles.header_right}>
        <span className={styles.header_right_close}>
          <CloseIcon />
        </span>
      </div> */}
    </div>
  );
};

export default Header;
