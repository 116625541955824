import React, { FC, memo } from "react";
import styles from "./bodyContainer.module.scss";

interface IProps {
  status: string;
  id: number;
  number: string;
  setOpenDrawer: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
    }>
  >;
  flatId: number;
  disable: boolean;
  news: boolean;
}

const Apartament: FC<IProps> = ({
  number,
  setOpenDrawer,
  status,
  id,
  flatId,
  disable,
  news,
}) => {
  const flatHundle = (): void => {
    setOpenDrawer({ id: id, visible: true });
  };

  return news ? (
    <div
      className={styles.bodyContainer_body_entrance_floor_apartament}
      onClick={flatHundle}
      style={{
        backgroundColor: "#ffffff",
        border: "1px solid #e0e0e0",
        pointerEvents: "none",
      }}
    ></div>
  ) : (
    <div
      className={`${styles.bodyContainer_body_entrance_floor_apartament} ${
        id === flatId
          ? styles.bodyContainer_body_entrance_floor_apartament_active
          : ""
      }`}
      onClick={flatHundle}
      style={{
        backgroundColor:
          status === "SOLD"
            ? "#FF6565"
            : status === "BOOKED"
            ? "#FFB400"
            : status === "RESERVE"
            ? "#A6A6A6"
            : "#63CBA5",
        opacity: disable ? 0.3 : 1,
        pointerEvents: disable ? "none" : "auto",
      }}
    >
      {number}
    </div>
  );
};

export default memo(Apartament);
