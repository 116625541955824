import { addControllerPrefix } from "../helper/addControllerPrefix";

export const controllers = {
  AMO_CRM: "/v1/amo-crm-sheets",
  WEBSITE: "/v1/website",
};

// amoCrm
export const amoCrm = {
  All_HOUSE_BY: "/all-house-by",
  ALL_BUILDING_BY: "/all-building-by",
  ALL_DATA_BY_FILTER: "/all-data-by-filter",
  FLAT_DATA_BY: "/flat-data-by",
  PETITION_CREATE: "/petition-create",
};

export const amoCrmEndpoints: typeof amoCrm = addControllerPrefix(
  amoCrm,
  controllers.AMO_CRM
);

// website
const website = {
  GET_ALL_HOUSE: "/get-all-house",
  BLOCK_GET_ALL_FLAT_BY_FILTER: "/block/get-all-flat-by-filter",
  BLOCK_FLAT_ALL_DATA: "/block/flat-all-data",
  BLOCK_STATISTIC: "/block-statistic",
  BLOCK_FLOOR_STATISTIC: "/block-floor-statistic",
  BLOCK_FLAT_DATA: "/block-flat-data",
  FLAT_SHOURM_PDF: "/flat-shourum-pdf",
  CREATE_LEAD_AMO_CRM: "/create-lead-amo-crm",
};

export const websiteEndpoints: typeof website = addControllerPrefix(
  website,
  controllers.WEBSITE
);
