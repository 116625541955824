import React, { FC } from "react";
import styles from "./bodyContainer.module.scss";

interface IProps {
  style?: React.CSSProperties | undefined;
  floorLength: number;
  floorMaxCount: number;
}

const NumberContainer: FC<IProps> = ({ style, floorLength, floorMaxCount }) => {
  return (
    <div className={styles.bodyContainer_body_numberContainer} style={style}>
      {Array.from({ length: floorLength })
        .map((item, index) => (
          <span key={index}>{index - (floorLength - floorMaxCount) + 1}</span>
        ))
        .reverse()}
    </div>
  );
};

export default NumberContainer;
