import React, { FC } from "react";
import styles from "./bodyContainer.module.scss";
import Floor from "./Floor";

interface IProps {
  setOpenDrawer: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
    }>
  >;
  data: any[];
  flatId: number;
}

const Building: FC<IProps> = ({ data, setOpenDrawer, flatId }) => {
  return (
    <div className={styles.bodyContainer_body_entrance_floor_entrance}>
      {data &&
        data
          .map((item, index) => (
            <Floor
              data={item}
              key={index}
              setOpenDrawer={setOpenDrawer}
              flatId={flatId}
            />
          ))
          .reverse()}
    </div>
  );
};

export default Building;
