import { Radio, Slider, Switch } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import ReceiveSquereIcon from "../../assets/icons/ReceiveSquereIcon";
import {
  AllHouseCopy,
  AllHouseFilter,
  AllHouseFilterDefault,
  HouseReload,
} from "../../atom";
import { AllFlat } from "../../utils/modules/AllFlat";
import styles from "./filter.module.scss";

interface IProps {
  allDataByFilter: AllFlat | undefined;
}

const Filter: FC<IProps> = ({ allDataByFilter }) => {
  const [houseCopy, setHouseCopy] = useRecoilState<any>(AllHouseCopy);
  const [filter, setFilter] = useRecoilState<any>(AllHouseFilter);
  const filterDefault = useRecoilValue(AllHouseFilterDefault);
  const setReload = useSetRecoilState<any>(HouseReload);
  const [position, setPosition] = useState({
    startY: 0,
    startX: 0,
    scrollLeft: 0,
    scrollTop: 0,
    isDown: false,
  });
  const bodyRef = useRef<any>(null);

  const houseCopyChange = () => {
    let arr: any = [];

    allDataByFilter?.buildingAmoCrmList?.forEach((item) => {
      item?.floorFlatList?.forEach((floor) => {
        floor?.entranceList?.forEach((entrance) => {
          entrance?.flatList?.forEach((flat) => {
            arr.push(flat.id);
          });
        });
      });
    });

    setHouseCopy(
      houseCopy?.map((block: any) => {
        return block?.map((floor: any) => {
          return floor?.map((entrance: any) => {
            return entrance?.map((flat: any) => {
              return {
                ...flat,
                disable: arr.includes(flat.id) ? false : true,
              };
            });
          });
        });
      })
    );
  };

  useEffect(() => {
    houseCopyChange();
    // eslint-disable-next-line
  }, [allDataByFilter, filter]);

  const bodymouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (position.isDown) {
      e.preventDefault();
      const y = e.pageY - bodyRef.current.offsetTop;
      const walkY = y - position.startY;
      bodyRef.current.scrollTop = position.scrollTop - walkY;

      const x = e.pageX - bodyRef.current.offsetLeft;
      const walkX = x - position.startX;
      bodyRef.current.scrollLeft = position.scrollLeft - walkX;
    }
  };
  const bodymouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition((prev) => ({
      ...prev,
      isDown: false,
    }));
  };
  const bodymouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition({
      isDown: true,
      startY: e.pageY - bodyRef.current.offsetTop,
      startX: e.pageX - bodyRef.current.offsetLeft,
      scrollTop: bodyRef.current.scrollTop,
      scrollLeft: bodyRef.current.scrollLeft,
    });
  };
  const bodymouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition((prev) => ({
      ...prev,
      isDown: false,
    }));
  };

  const flatCountHandle = (value: any, index: number) => {
    console.log(value.target.checked);
    setFilter({
      ...filter,
      rooms: value.target.checked
        ? [...filter?.rooms, index]
        : filter?.rooms.filter((item: number) => item !== index),
    });
  };

  return (
    <div
      className={styles.filter}
      onMouseMove={bodymouseMove}
      onMouseUp={bodymouseUp}
      onMouseLeave={bodymouseLeave}
      onMouseDown={bodymouseDown}
      ref={bodyRef}
    >
      <div className={styles.filter_container}>
        <div className={styles.filter_container_title}>
          Filtr{" "}
          <span
            onClick={() => {
              setFilter({
                houseId: filter?.houseId,
                rooms: filterDefault?.rooms,
                maxArea: filterDefault?.maxArea,
                minArea: filterDefault?.minArea,
                maxPrice: filterDefault?.maxPrice,
                minPrice: filterDefault?.minPrice,
                maxPerPrice: filterDefault?.maxPerPrice,
                minPerPrice: filterDefault?.minPerPrice,
                repaired: "null",
                empty: false,
                attached: false,
              });
              setReload(true);
            }}
          >
            <ReceiveSquereIcon />
            {""}
            Tozalash
          </span>
        </div>
        <div className={styles.filter_container_flatsCount}>
          <h1>Xonalar soni</h1>
          <div>
            {filterDefault?.rooms.map((i: number) => (
              <>
                <input
                  checked={
                    filter?.rooms &&
                    filter?.rooms.some((item: any) => item === i)
                  }
                  onChange={(e: any) => flatCountHandle(e, i)}
                  id={"checkBox" + i}
                  type={"checkbox"}
                />
                <label htmlFor={"checkBox" + i}>{i}</label>
              </>
            ))}
          </div>
        </div>
        <div className={styles.filter_container_flatSlider}>
          <h1>Xonadon umumiy narxi</h1>
          <Slider
            marks={Array.from({ length: 5 }, (_, i) => i + 1).reduce(
              (acc: any, item: any) => ({
                ...acc,
                0: "0",
                [Math.floor(
                  item * (Number(filterDefault.maxPrice / 5) / 1000000)
                )]: String(
                  Math.floor(
                    item * (Number(filterDefault.maxPrice / 5) / 1000000)
                  )
                ),
              }),
              {}
            )}
            range
            defaultValue={[
              filterDefault.minPrice / 1000000,
              filterDefault.maxPrice / 1000000,
            ]}
            value={[filter?.minPrice / 1000000, filter?.maxPrice / 1000000]}
            min={0}
            max={filterDefault.maxPrice / 1000000}
            onChange={(value) => {
              setFilter({
                ...filter,
                minPrice: value[0] * 1000000,
                maxPrice: value[1] * 1000000,
              });
            }}
          />
          <p>Barcha narxlar millionlarda</p>
        </div>
        <div className={styles.filter_container_flatSlider}>
          <h1>
            Umumiy maydon (m<sup>2</sup>)
          </h1>
          <Slider
            marks={Array.from({ length: 4 }, (_, i) => i + 1).reduce(
              (acc: any, item: any) => ({
                ...acc,
                0: "0",
                [item * Number(filterDefault.maxArea / 4)]: String(
                  Math.floor(item * Number(filterDefault.maxArea / 4))
                ),
              }),
              {}
            )}
            range
            defaultValue={[filterDefault.minArea, filterDefault.maxArea]}
            min={0}
            max={filterDefault.maxArea}
            value={[filter?.minArea, filter?.maxArea]}
            onChange={(value) => {
              setFilter({
                ...filter,
                minArea: value[0],
                maxArea: value[1],
              });
            }}
          />
        </div>
        <div className={styles.filter_container_flatSlider}>
          <h1>
            Narx (1 m<sup>2</sup> uchun)
          </h1>
          <Slider
            marks={Array.from({ length: 5 }, (_, i) => i + 1).reduce(
              (acc: any, item: any) => ({
                ...acc,
                0: "0",
                [item * (Number(filterDefault.maxPerPrice / 5) / 1000000)]:
                  String(
                    Math.floor(
                      item * (Number(filterDefault.maxPerPrice / 5) / 1000000)
                    )
                  ),
              }),
              {}
            )}
            range
            defaultValue={[
              filterDefault.minPerPrice / 1000000,
              filterDefault.maxPerPrice / 1000000,
            ]}
            value={[
              filter?.minPerPrice / 1000000,
              filter?.maxPerPrice / 1000000,
            ]}
            min={0}
            max={filterDefault.maxPerPrice / 1000000}
            onChange={(value) => {
              setFilter({
                ...filter,
                minPerPrice: value[0] * 1000000,
                maxPerPrice: value[1] * 1000000,
              });
            }}
          />
          <p>Barcha narxlar millionlarda</p>
        </div>
        <div className={styles.filter_container_repaired}>
          <h1>Ta’mir holati</h1>
          <Radio.Group
            value={filter?.repaired}
            onChange={(value) => {
              setFilter({
                ...filter,
                repaired: value.target.value,
              });
            }}
          >
            <Radio value={"null"}>Barchasi</Radio>
            <Radio value={"false"}>Ta’mirsiz</Radio>
            <Radio value={"true"}>Ta’mirli</Radio>
          </Radio.Group>
        </div>
        <div className={styles.filter_container_switch}>
          <label>
            Bo’sh xonadonlar{" "}
            <Switch
              checked={filter?.empty}
              onChange={(value) => {
                setFilter({
                  ...filter,
                  empty: value,
                });
              }}
            />
          </label>
          <label>
            Biriktirilganlar{" "}
            <Switch
              checked={filter?.attached}
              onChange={(value) => {
                setFilter({
                  ...filter,
                  attached: value,
                });
              }}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default Filter;
