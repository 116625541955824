import { message } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { $api } from "../services/RequestService";
import { amoCrmEndpoints, websiteEndpoints } from "../utils/constants/apis";
import { queryNames } from "../utils/constants/queryNames";
import { CreateLeadAmo } from "../utils/modules/CreateLeadAmo";
import queryString from "query-string";

export function usePetitionCreate(id: number) {
  const qc = useQueryClient();
  return useMutation(
    async (data: { leadId: string; flatId: string }) => {
      const res = await $api.post(
        amoCrmEndpoints.PETITION_CREATE +
          `?leadId=${data.leadId}&flatId=${data.flatId}`
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        message.success("Saqlandi");
        qc.invalidateQueries([queryNames.FLAT_DATA_BY, id]);
      },
      onError: (err: any) => {
        console.log(err.response?.data?.message);
        message.error(err.response?.data?.message.uz);
      },
    }
  );
}

export function useCreateLead() {
  return useMutation(
    async (data: CreateLeadAmo) => {
      const res = await $api.post(websiteEndpoints.CREATE_LEAD_AMO_CRM, data, {
        headers: {
          "X-Auth": String(queryString.parse(window.location.search)?.token),
        },
      });
      return res.data;
    },
    {
      onSuccess: () => {
        message.success("Ma'lumotlar yuborildi!");
      },
      onError: () => {
        message.error("Ma'lumotlarni yuborishda xatolik mavjud!");
      },
    }
  );
}
