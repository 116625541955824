import { useQuery } from "react-query";
import { $api, $apiVisual } from "../services/RequestService";
import { amoCrmEndpoints, websiteEndpoints } from "../utils/constants/apis";
import { queryNames } from "../utils/constants/queryNames";
import { AllDataByFilter } from "../utils/modules/AllDataByFilter";
import { AllFlat } from "../utils/modules/AllFlat";
import { AllHouse } from "../utils/modules/AllHouse";
import { FlatVisual } from "../utils/modules/FlatVisual";
import queryString from "query-string";
import {
  AllHouseCopy,
  AllHouseFilter,
  AllHouseFilterDefault,
  HouseReload,
} from "../atom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { HouseAllData } from "../utils/modules/HouseAllData";
import { BlockFloorStatistic } from "../utils/modules/BlockFloorStatistic";
import { FlatStatistics } from "../utils/modules/FlatStatistics";

export function useAllHouseBy() {
  return useQuery<AllHouse[]>(
    [queryNames.All_HOUSE_BY],
    async () => {
      const res = await $api.get(amoCrmEndpoints.All_HOUSE_BY);
      return res.data.data;
    },
    {
      enabled: !queryString.parse(window.location.search)?.simple,
    }
  );
}

export function useAllBuildingBy(id: number) {
  return useQuery<AllHouse[]>(
    [queryNames.ALL_BUILDING_BY, id],
    async () => {
      const res = await $api.get(amoCrmEndpoints.ALL_BUILDING_BY + `/${id}`);
      return res.data.data;
    },
    {
      enabled: !queryString.parse(window.location.search)?.simple && !!id,
    }
  );
}

export function useAllDataByFilter(data: AllDataByFilter) {
  const setHouseCopy = useSetRecoilState<any>(AllHouseCopy);
  const [getAllHouseFilter, setAllHouseFilter] =
    useRecoilState<any>(AllHouseFilter);
  const setAllHouseFilterDefault = useSetRecoilState<any>(
    AllHouseFilterDefault
  );
  const [reload, setReload] = useRecoilState(HouseReload);
  return useQuery<AllFlat>(
    [queryNames.ALL_BUILDING_BY, { ...data }],
    async () => {
      const res = await $api.post(amoCrmEndpoints.ALL_DATA_BY_FILTER, data);

      return res.data.data;
    },
    {
      onSuccess: (data) => {
        if (reload) {
          setHouseCopy([]);
          setAllHouseFilter({
            ...getAllHouseFilter,
            rooms: Array.from(
              { length: data.amoCrmFilterProperties.maxRoomsCount },
              (_, i) => i + 1
            ),
            maxArea: data.amoCrmFilterProperties.maxArea,
            minArea: 0,
            maxPrice: data.amoCrmFilterProperties.maxPrice,
            minPrice: 0,
            maxPerPrice: data.amoCrmFilterProperties.maxPerPrice,
            minPerPrice: 0,
          });

          setAllHouseFilterDefault({
            rooms: Array.from(
              { length: data.amoCrmFilterProperties.maxRoomsCount },
              (_, i) => i + 1
            ),
            maxArea: data.amoCrmFilterProperties.maxArea,
            minArea: 0,
            maxPrice: data.amoCrmFilterProperties.maxPrice,
            minPrice: 0,
            maxPerPrice: data.amoCrmFilterProperties.maxPerPrice,
            minPerPrice: 0,
            repaired: "null",
            empty: false,
            attached: false,
          });
          let arr: any = [];
          let floorLength: number = 0;
          let floorLength2: number[] = [];
          let entranceLength: number = 0;
          let flatLength: number = 0;
          data?.buildingAmoCrmList?.forEach((item) => {
            if (floorLength < item.floorFlatList.length) {
              floorLength = item.floorFlatList.length;
            }
            item?.floorFlatList?.forEach((floor) => {
              if (entranceLength < floor.entranceList.length) {
                entranceLength = floor.entranceList.length;
              }
              if (!floorLength2.includes(floor.floor)) {
                floorLength2.push(floor.floor);
              }
              floor?.entranceList?.forEach((entrance) => {
                if (flatLength < entrance.flatList.length) {
                  flatLength = entrance.flatList.length;
                }
                entrance?.flatList?.forEach((flat) => {
                  arr.push({
                    ...flat,
                    floor: floor.floor,
                    entrance: entrance.entrance,
                    buildingId: item.id,
                  });
                });
              });
            });
          });

          for (let i = 1; i < floorLength; i++) {
            if (!floorLength2.includes(i)) {
              floorLength2.push(i);
            }
          }

          let result = data?.buildingAmoCrmList.map((b, index) => {
            let flatlen = 0;
            let flatent = 0;
            let flatfloor = 0;
            b.floorFlatList.forEach((fl) => {
              if (flatfloor < fl.floor) {
                flatfloor = fl.floor;
              }
              fl.entranceList.forEach((e) => {
                if (flatent < e?.entrance) {
                  flatent = e?.entrance;
                }
                if (flatlen < e.flatList.length) {
                  flatlen = e.flatList.length;
                }
              });
            });

            return Array.from({ length: flatent }, (__, e) => {
              return floorLength2
                .sort((a: number, b: number) => a - b)
                .map((floor, floorInt) => {
                  return Array.from(
                    {
                      length: flatlen,
                    },
                    (____, flat) => {
                      return {
                        buildingName: b.buildingName,
                        buildingId: b.id,
                        entrance: e + 1,
                        floor: floor,
                        xaxis: String(flat + 1),
                        yaxis: String(floorInt + 1),
                      };
                    }
                  );
                });
            });
          });

          let result2 = result?.map((building) => {
            return building.map((entrance) => {
              return entrance.map((floor) => {
                return floor.map((flat) => {
                  function New(a: any) {
                    return (
                      String(a?.floor) &&
                      String(a?.floor) === String(flat?.floor) &&
                      a?.xaxis &&
                      a?.xaxis === flat?.xaxis &&
                      a?.entrance &&
                      a?.entrance === flat?.entrance &&
                      a?.buildingId &&
                      a?.buildingId === flat?.buildingId
                    );
                  }

                  return arr && arr?.some((el: any) => New(el))
                    ? {
                        ...arr?.find((el: any) => New(el)),
                        new: false,
                        buildingName: flat?.buildingName,
                      }
                    : {
                        ...flat,
                        new: true,
                      };
                });
              });
            });
          });

          setHouseCopy(result2);
        }
        setReload(false);
      },
      enabled: !queryString.parse(window.location.search)?.simple,
    }
  );
}

export function useFlatDataBy(id: number) {
  return useQuery<FlatVisual>(
    [queryNames.FLAT_DATA_BY, id],
    async () => {
      if (queryString.parse(window.location.search)?.simple) {
        const res = await $apiVisual.get(
          websiteEndpoints.BLOCK_FLAT_ALL_DATA + `/${id}`
        );
        return res.data.data;
      } else {
        const res = await $api.get(
          amoCrmEndpoints.FLAT_DATA_BY +
            `/${id}?leadId=${queryString.parse(window.location.search).leadId}`
        );
        return res.data.data;
      }
    },
    {
      enabled: !!id,
    }
  );
}

export function useBlockStatistics() {
  return useQuery<any>([queryNames.GET_ALL_HOUSE], async () => {
    const res = await $apiVisual.get(websiteEndpoints.GET_ALL_HOUSE);
    return res.data.data?.data;
  });
}

export function useBlockStatistic(houseId: number) {
  return useQuery<HouseAllData>(
    [queryNames.BLOCK_STATISTIC, houseId],
    async () => {
      const res = await $apiVisual.get(
        websiteEndpoints.BLOCK_STATISTIC + "/" + houseId
      );
      return res.data.data;
    },
    {
      enabled: !!houseId,
    }
  );
}

export function useBlockFloorStatistic(blockId: number) {
  return useQuery<BlockFloorStatistic>(
    [queryNames.BLOCK_FLOOR_STATISTIC, blockId],
    async () => {
      const res = await $apiVisual.get(
        websiteEndpoints.BLOCK_FLOOR_STATISTIC + "/" + blockId
      );
      return res.data.data;
    },
    {
      enabled: !!blockId,
    }
  );
}

export function useBlockFlatStatistic(blockId: number, floor: number) {
  return useQuery<FlatStatistics>(
    [queryNames.BLOCK_FLAT_DATA, blockId, floor],
    async () => {
      const res = await $apiVisual.get(
        websiteEndpoints.BLOCK_FLAT_DATA + "/" + blockId + "?floor=" + floor
      );
      return res.data.data;
    },
    {
      enabled: !!blockId,
    }
  );
}
