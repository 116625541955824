import React from "react";
import "antd/dist/antd.variable.min.css";
import "./global.scss";
import Home from "./components/Home/Home";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient();

function App() {
  ConfigProvider.config({
    theme: {
      primaryColor: "#00A389",
    },
  });

  return (
    <BrowserRouter>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Home />
        </QueryClientProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
